.static-sidebar{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 20px !important;
  border: 1px solid #EEEEEE!important;
  border-radius: 5px;
  .item_con{
    p{
      margin-bottom: 10px;
    }
    &:not(:last-child){
      margin-bottom: 10px;
      border-bottom: 1px solid #EEEEEE;
    }
    &.active{
      p{
        color: #0491A4;
      }
      
      hr{
      background-color: #0491A4;
      }
    }
    hr{
      background-color: white;
      width: 20%;
      height: 2px;
      opacity: 1;
      margin: 0;
      border:none;
    }
  }
  @media(max-width:991px) {
    flex-direction: row;
    border: none !important;
    .item_con{
      margin-bottom: 0px !important;
      border: 2px solid rgb(249, 249, 249) !important;
      margin-right: 10px;
      padding: 5px;
      &.active{
        border:2px solid #0491A4 !important;
        p{
          color:black;
        }
      }
      hr{
        display: none;
      }
      p{
        
        margin-bottom: 0px !important;
      }
    }
  }
}

.profile-sidebar{
}