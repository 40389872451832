.aboutus{
  padding-block: 50px;
  @media(max-width:768px) {
    padding-top: 20px;
  }
  .border-radius{
    border-radius: 8px;
  }
  .about-section{
    margin-bottom: 40px;
    @media(max-width:991px) {
        flex-direction: column-reverse;
        margin-bottom: 20px;
      }  
    }
  .mission-section{
    img{
      margin-bottom: 30px;
      width: 100px;
      height: 80px;
      @media(max-width:991px) {
        margin-bottom: 8px;
      }
    }
    .card{
      display: flex;
      flex-direction: column;
      padding: 30px;
      height: 100%;
      .flex{
        flex-direction: column;
        @media(max-width: 991px){
          flex-direction: row;}
      }
      @media(max-width: 991px){
        padding: 17px;
      }
    }
      @media(max-width: 991px){
        gap: 15px;
      }
  }
  .news-section{
    margin-top: 40px;
    h1{
      text-align: center;
      margin-bottom: 20px;
    }
    @media(max-width: 991px){
      margin-top: 20px;
      h1{
        margin-bottom: 0;
      }
      gap: 10px; 
    }
  }
  .view-btn{
    margin-top: 30px;
    a{ 
      text-decoration: none; 
      cursor: pointer;
      &:hover,&:focus{
        color: #0491A4;
        }
      }
  }
}
.contactus-container{
  padding-block: 50px;
  h1{
    @media(max-width: 991px){
      padding-top: 30px;
      line-height: 34px;}
    }
  .row{
  .social{
    img{
      max-width: 24px;
      }
    }
    }
  form{
    button{
      float: right;
      p{ text-transform: uppercase;}
    }
  }
  .message-form{
    .label_txt{
      margin-bottom: 5px;
      }
    }
  .row{
    margin-top: 50px;
    @media(max-width: 991px){
      margin-top: 20px;}
    }
  .branch{
    .title{
      text-transform: uppercase;}
    }
  .address{
    p{
      max-width: 400px;
    }
  }
  @media(max-width: 991px){
    padding-top: 0;
  }
  
}

.not-found-page{
  min-height: 60vh;
  padding-block: 50px;
  }